import React from "react"
import LayoutPolicy from "../components/layout-policy"
import PrivacyPolicHeader from "../components/sections/privacy-policy-header"
import PrivacyPolicySection from "../components/sections/privacy-policy-section"
import SEO from "../components/seo"

import YAMLData from "../content/privacyPolicyData.yaml"

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <LayoutPolicy>
        <SEO title="HydroSzpak" />
        <PrivacyPolicHeader props={YAMLData.headerSection} />

        {YAMLData.mainSections.map((item, i) => {
          return <PrivacyPolicySection props={item.sectionElement} key={i} />
        })}
      </LayoutPolicy>
    )
  }
}

export default PrivacyPolicy
