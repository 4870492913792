import PropTypes from "prop-types"
import React from "react"

const PrivacyPolicHeader = ({ props }) => (
  <header className="privacy-policy-header">
    {React.createElement(props.headerTag, {
      className: "privacy-policy-header-text",
      dangerouslySetInnerHTML: {
        __html: props.headerText,
      },
    })}
  </header>
)

PrivacyPolicHeader.propTypes = {
  props: PropTypes.shape({
    headerTag: PropTypes.string,
    headerText: PropTypes.string,
  }),
}

PrivacyPolicHeader.defaultProps = {
  props: {
    headerTag: "",
    headerText: "",
  },
}

export default PrivacyPolicHeader
