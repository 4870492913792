import PropTypes from "prop-types"
import React from "react"

const PrivacyPolicySection = ({ props }) => (
  <section className="privacy-policy-section">
    {React.createElement(props.sectionHeaderTag, {
      className: "privacy-policy-section-header-text",
      dangerouslySetInnerHTML: {
        __html: props.sectionHeaderText,
      },
    })}
    <div
      className="privacy-policy-section-text"
      dangerouslySetInnerHTML={{ __html: props.sectionText }}
    />
  </section>
)

PrivacyPolicySection.propTypes = {
  props: PropTypes.shape({
    sectionHeaderTag: PropTypes.string,
    sectionHeaderText: PropTypes.string,
    sectionText: PropTypes.string,
  }),
}

PrivacyPolicySection.defaultProps = {
  props: {
    sectionHeaderTag: "",
    sectionHeaderText: "",
    sectionText: "",
  },
}

export default PrivacyPolicySection
