import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import "../assets/scss/main.scss"

class LayoutPolicy extends React.Component {
  constructor(props) {
    super(props)
    this.children = props.children
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <main className="main-doc-layout">
              <div className="doc-content">{this.children}</div>
            </main>
          </>
        )}
      />
    )
  }
}

LayoutPolicy.propTypes = {
  children: PropTypes.node,
}

export default LayoutPolicy
